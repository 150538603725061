/**
 * @ComponentFor CountdownBannerBlockViewModel
 */

import React from 'react';
import { translate } from '@avensia/scope';
import { styled } from '@glitz/react';
import {
  minMediumMediaQuery,
  minLargeMediaQuery,
  minHugeMediaQuery,
  minTinyMediaQuery,
  sigma,
  epsilon,
  delta,
  beta,
  giga,
  white,
  black,
  pixelsToUnit,
  truncate,
} from 'Shared/Style';
import P from 'Shared/Generic/P';
import CountdownBannerBlockType from './CountdownBannerBlockViewModel.type';
import { epiPropertyValue } from '@avensia/scope-episerver';
import Countdown from 'Shared/Countdown';
import MysteryCard from 'Product/ProductCard/MysteryCard';
import Product from 'Product/ProductCard/ProductCard';
import { addToCart } from 'Cart/action-creators';
import connect from 'Shared/connect';

type ConnectStateStype = { currentBreakpoint: number };

type ConnectActionType = {
  addToCart: (code: string, quantity: number, ticket: string) => Promise<void>;
};

type PropType = CountdownBannerBlockType & ConnectActionType & ConnectStateStype;

const CountdownBannerBlock = (props: PropType) => {
  const {
    maskBackground,
    imageBackground,
    headingColor,
    heading,
    subHeadingColor,
    subHeading,
    startCountdownHeadingColor,
    startCountdownHeading,
    startTimeDotColor,
    showTimerDot,
    timerUnitColor,
    backgroundProductColor,
  } = props.block;

  const code = !!props.product && epiPropertyValue(props.product.variation.code);
  const splitDateTime = props.eventDate.split(' ');
  const eventDate = splitDateTime[0];
  const eventTime = splitDateTime[1];

  return (
    ((!props.hasEventStarted && props.product === null) || (props.hasEventStarted && !!props.product)) && (
      <styled.Div
        css={{
          display: 'flex',
          flexFlow: 'row wrap',
        }}
      >
        <Campaign css={{ backgroundImage: `url(${epiPropertyValue(imageBackground)})` }}>
          <Mask css={{ backgroundColor: epiPropertyValue(maskBackground) ? 'rgba(0, 0, 0, 0.3)' : 'transparent' }}>
            <Details>
              {!!heading && (
                <Heading css={{ color: epiPropertyValue(headingColor) || black }}>{epiPropertyValue(heading)}</Heading>
              )}

              {!!subHeading && (
                <SubHeading css={{ color: epiPropertyValue(subHeadingColor) || black }} noSpacing>
                  {epiPropertyValue(subHeading)}
                </SubHeading>
              )}
            </Details>

            <DateBadge>
              <EventDate>{eventDate}</EventDate>
              <EventTime>
                {translate('/CountdownBanner/StartsAt')} {eventTime}
              </EventTime>
            </DateBadge>

            <Timer>
              {!!startCountdownHeading && (
                <TimerHeading css={{ color: epiPropertyValue(startCountdownHeadingColor) || black }}>
                  {epiPropertyValue(startCountdownHeading)}
                </TimerHeading>
              )}
              <CountdownTimer
                date={props.countDownDateTime}
                showTimerDot={epiPropertyValue(showTimerDot)}
                timerDotColor={epiPropertyValue(startTimeDotColor) || white}
                timeUnitColor={epiPropertyValue(timerUnitColor) || white}
                breakpoint={props.currentBreakpoint}
              />
            </Timer>
          </Mask>
        </Campaign>
        <styled.Div
          css={{
            backgroundColor: epiPropertyValue(backgroundProductColor) || black,
            display: 'flex',
            justifyContent: 'space-between',
            width: '100%',
            padding: { xy: pixelsToUnit(15) },
            [minMediumMediaQuery]: {
              width: '35%',
              padding: { xy: pixelsToUnit(20) },
            },
          }}
        >
          {!!props.product ? (
            <ProductWrapper>
              <Product product={props.product} addToCart={() => props.addToCart(code, 1, props.product.ticket)} />
            </ProductWrapper>
          ) : (
            <MysteryCard css={{ width: '45%' }} />
          )}
          <MysteryCard css={{ width: '45%' }} />
        </styled.Div>
      </styled.Div>
    )
  );
};

export default connect(
  (state): ConnectStateStype => ({
    currentBreakpoint: state.currentBreakpoint,
  }),
  (dispatch): ConnectActionType => ({
    addToCart(code: string, quantity: number, ticket: string) {
      return dispatch(addToCart(code, quantity, ticket, 'Produktlista'));
    },
  }),
)(CountdownBannerBlock);

const CountdownTimer = styled(Countdown, {
  display: 'flex',
  flexFlow: 'row wrap',
  justifyContent: 'center',
  [minTinyMediaQuery]: {
    padding: {
      x: pixelsToUnit(5),
    },
  },
  [minMediumMediaQuery]: {
    justifyContent: 'flex-start',
  },
});

const ProductWrapper = styled.div({
  width: '45%',
});

const Campaign = styled.div({
  backgroundSize: 'cover',
  width: '100%',
  [minMediumMediaQuery]: {
    width: '65%',
  },
});

const Mask = styled.div({
  height: '100%',
  width: '100%',
  [minMediumMediaQuery]: {
    display: 'flex',
    flexFlow: 'row wrap',
    justifyContent: 'space-between',
    padding: { y: pixelsToUnit(40), x: pixelsToUnit(25) },
  },
});

const Details = styled.div({
  textAlign: 'center',
  [minMediumMediaQuery]: {
    maxWidth: '60%',
    overflow: 'hidden',
    textAlign: 'left',
  },
  [minLargeMediaQuery]: {
    maxWidth: '70%',
  },
  [minHugeMediaQuery]: {
    maxWidth: '75%',
  },
});

const Heading = styled.h1({
  fontSize: pixelsToUnit(44),
  fontWeight: 'bold',
  letterSpacing: pixelsToUnit(0.49),
  lineHeight: pixelsToUnit(57),
  textTransform: 'uppercase',
  [minMediumMediaQuery]: {
    ...truncate(),
    fontSize: pixelsToUnit(59),
  },
  [minLargeMediaQuery]: {
    fontSize: giga,
  },
  [minHugeMediaQuery]: {
    fontSize: pixelsToUnit(92),
    letterSpacing: pixelsToUnit(1.02),
    lineHeight: pixelsToUnit(119),
  },
});

const SubHeading = styled(P, {
  fontSize: sigma,
  letterSpacing: pixelsToUnit(0.16),
  lineHeight: pixelsToUnit(18),
  [minMediumMediaQuery]: {
    ...truncate(),
    fontSize: epsilon,
    lineHeight: pixelsToUnit(50),
  },
  [minHugeMediaQuery]: {
    fontSize: beta,
    letterSpacing: pixelsToUnit(0.29),
    lineHeight: pixelsToUnit(33),
  },
});

const DateBadge = styled.div({
  alignItems: 'center',
  backgroundColor: theme => theme.primaryColor,
  borderRadius: '50%',
  color: white,
  display: 'flex',
  flexDirection: 'column',
  height: pixelsToUnit(110),
  justifyContent: 'center',
  margin: { y: pixelsToUnit(15), x: 'auto' },
  width: pixelsToUnit(110),
  [minMediumMediaQuery]: {
    height: pixelsToUnit(180),
    margin: { xy: 0 },
    width: pixelsToUnit(180),
  },
  [minHugeMediaQuery]: {
    height: pixelsToUnit(206),
    width: pixelsToUnit(206),
  },
});

const EventDate = styled.h3({
  fontSize: epsilon,
  fontWeight: 'bold',
  marginBottom: pixelsToUnit(5),
  [minMediumMediaQuery]: {
    fontSize: pixelsToUnit(51),
    letterSpacing: pixelsToUnit(0.57),
    lineHeight: pixelsToUnit(66),
  },
});

const EventTime = styled.span({
  display: 'block',
  fontSize: sigma,
  fontWeight: 'bold',
  [minMediumMediaQuery]: {
    display: 'inline',
    fontSize: delta,
  },
});

const Timer = styled.div({
  width: '100%',
});

const TimerHeading = styled.h3({
  textAlign: 'center',
  marginBottom: pixelsToUnit(15),
  [minMediumMediaQuery]: {
    textAlign: 'left',
  },
});
