import React from 'react';
import { kappa, white, black, pixelsToUnit, minTinyMediaQuery } from 'Shared/Style';
import { styled, StyledProps } from '@glitz/react';
import { Style } from '@glitz/type';
import { translate, currentUrl, replaceState } from '@avensia/scope';

type PropType = {
  date: string;
  showTimerDot: boolean;
  timerDotColor?: string;
  timeUnitColor?: string;
  breakpoint?: number;
} & StyledProps;

type StateType = {
  days: number;
  hours: number;
  min: number;
  sec: number;
};

class Countdown extends React.Component<PropType, StateType> {
  interval: number = null;

  constructor(props: PropType) {
    super(props);

    this.state = {
      days: 0,
      hours: 0,
      min: 0,
      sec: 0,
    };
  }

  startTimer(d: string) {
    const parseDate = new Date(d);
    this.interval = setInterval(() => {
      const date = this.calculateCountdown(parseDate);
      date ? this.setState(date) : this.stop();
    });
  }

  componentDidUpdate(prevProps: PropType) {
    if (prevProps.date !== this.props.date) {
      this.startTimer(this.props.date);
    }
  }

  componentDidMount() {
    this.startTimer(this.props.date);
  }

  componentWillUnmount() {
    this.stop();
  }

  calculateCountdown(endDate: Date) {
    let diff = (Date.parse(new Date(endDate).toString()) - Date.parse(new Date().toString())) / 1000;

    if (diff <= 0) {
      return false;
    }

    const timeLeft = {
      years: 0,
      days: 0,
      hours: 0,
      min: 0,
      sec: 0,
      millisec: 0,
    };

    // calculate time difference between now and expected date
    if (diff >= 365.25 * 86400) {
      // 365.25 * 24 * 60 * 60
      timeLeft.years = Math.floor(diff / (365.25 * 86400));
      diff -= timeLeft.years * 365.25 * 86400;
    }
    if (diff >= 86400) {
      // 24 * 60 * 60
      timeLeft.days = Math.floor(diff / 86400);
      diff -= timeLeft.days * 86400;
    }
    if (diff >= 3600) {
      // 60 * 60
      timeLeft.hours = Math.floor(diff / 3600);
      diff -= timeLeft.hours * 3600;
    }
    if (diff >= 60) {
      timeLeft.min = Math.floor(diff / 60);
      diff -= timeLeft.min * 60;
    }
    timeLeft.sec = diff;

    return timeLeft;
  }

  stop() {
    const url = currentUrl();
    clearInterval(this.interval);
    replaceState(url);
  }

  addLeadingZeros(time: number, places: number) {
    return ('0000000000' + time).slice(-places);
  }

  splitTime(time: string) {
    return time.split('').map(Number);
  }

  formatTime(time: StateType) {
    const seconds = this.addLeadingZeros(time.sec, 2);
    const minutes = this.addLeadingZeros(time.min, 2);
    const hours = this.addLeadingZeros(time.hours, 2);
    const days = this.addLeadingZeros(time.days, 2);

    return {
      days: this.splitTime(days),
      hours: this.splitTime(hours),
      min: this.splitTime(minutes),
      sec: this.splitTime(seconds),
    };
  }

  renderTimerDots(dotColor: string, showDot: boolean) {
    const style: Style = {
      color: dotColor || 'inherit',
    };
    return showDot ? <TimerDot css={style}>:</TimerDot> : <DotPlaceholder />;
  }

  render() {
    const countDown = this.state;
    const { days, hours, min, sec } = this.formatTime(countDown);

    return (
      <styled.Div css={this.props.compose()}>
        <TimeWrapper>
          <Timer>
            {days.map((d, idx) => (
              <DisplayNum key={idx}>{d}</DisplayNum>
            ))}
            <TimeUnit css={{ color: this.props.timeUnitColor || 'inherit' }}>
              {countDown.days === 1 ? translate('/CountdownBanner/Day') : translate('/CountdownBanner/Days')}
            </TimeUnit>
          </Timer>
          {this.renderTimerDots(this.props.timerDotColor, this.props.showTimerDot)}
          <Timer>
            {hours.map((h, idx) => (
              <DisplayNum key={idx}>{h}</DisplayNum>
            ))}
            <TimeUnit css={{ color: this.props.timeUnitColor || 'inherit' }}>
              {translate('/CountdownBanner/Hours')}
            </TimeUnit>
          </Timer>
          {this.props.breakpoint > 1 && this.renderTimerDots(this.props.timerDotColor, this.props.showTimerDot)}
        </TimeWrapper>
        <TimeWrapper>
          <Timer>
            {min.map((m, idx) => (
              <DisplayNum key={idx}>{m}</DisplayNum>
            ))}
            <TimeUnit css={{ color: this.props.timeUnitColor || 'inherit' }}>
              {translate('/CountdownBanner/Minutes')}
            </TimeUnit>
          </Timer>
          {this.renderTimerDots(this.props.timerDotColor, this.props.showTimerDot)}
          <Timer>
            {sec.map((s, idx) => (
              <DisplayNum key={idx}>{s}</DisplayNum>
            ))}
            <TimeUnit css={{ color: this.props.timeUnitColor || 'inherit' }}>
              {translate('/CountdownBanner/Seconds')}
            </TimeUnit>
          </Timer>
        </TimeWrapper>
      </styled.Div>
    );
  }
}

export default Countdown;

const TimeUnit = styled.span({
  display: 'block',
  fontSize: kappa,
  textAlign: 'center',
  textTransform: 'uppercase',
});

const Timer = styled.div({
  color: white,
  fontSize: pixelsToUnit(37),
});

const DisplayNum = styled.span({
  backgroundColor: black,
  borderRadius: pixelsToUnit(3),
  color: white,
  fontSize: 'inherit',
  padding: { y: pixelsToUnit(4), x: pixelsToUnit(13) },
  [':first-child']: {
    marginRight: pixelsToUnit(3),
  },
});

const TimerDot = styled.span({
  fontSize: pixelsToUnit(37),
  margin: {
    x: pixelsToUnit(5),
  },
});

const DotPlaceholder = styled.span({
  width: pixelsToUnit(20),
  height: 'inherit',
});

const TimeWrapper = styled.div({
  display: 'flex',
  flexFlow: 'row wrap',
  justifyContent: 'center',
  width: '100%',
  [minTinyMediaQuery]: {
    justifyContent: 'flex-start',
    width: 'auto',
  },
});
