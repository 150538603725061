import React from 'react';
import { styled, StyledProps } from '@glitz/react';
import MaskIcon from 'Shared/Icon/Mask';
import {
  minTinyMediaQuery,
  minSmallMediaQuery,
  minMediumMediaQuery,
  minHugeMediaQuery,
  pixelsToUnit,
  giga,
} from 'Shared/Style';
import AspectRatio, { Ratio as boxRatio } from 'Shared/AspectRatio';

const MysteryCard = (props: StyledProps) => {
  return (
    <Base css={props.compose()}>
      <Body>
        <TopWrapper>
          <Placeholder>
            <MaskContainer ratio={boxRatio.OneToOne}>
              <Mask />
            </MaskContainer>
          </Placeholder>
        </TopWrapper>

        <CensorWrapper>
          <BrandCensor />
          <ProductNameCensor />
          <PriceCensor />
        </CensorWrapper>
      </Body>
    </Base>
  );
};

function setCensorStyle(
  height: number | string = 'inherit',
  width: number | string = 'inherit',
  marginTop: number | string = 'inherit',
  marginX: number | string = 'inherit',
) {
  return {
    backgroundColor: '#323232',
    height,
    width,
    margin: {
      top: typeof marginTop === 'string' ? marginTop : pixelsToUnit(marginTop),
      x: typeof marginX === 'string' ? marginX : pixelsToUnit(marginX),
    },
  };
}

const Base = styled.div({
  display: 'flex',
  flexDirection: 'column',
  minHeight: pixelsToUnit(250),
});

const Body = styled.div({
  alignItems: 'stretch',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
  paddingBottom: 0,
  color: 'inherit',
  textAlign: 'center',
  [minMediumMediaQuery]: {
    textAlign: 'left',
  },
  height: '100%',
});

const Placeholder = styled.div({
  position: 'relative',
  marginBottom: pixelsToUnit(20),
});

const TopWrapper = styled.div({
  overflow: 'hidden',
});

const Mask = styled(MaskIcon, {
  position: 'absolute',
  top: 0,
  right: 0,
  bottom: 0,
  left: 0,
  maxWidth: '100%',
  maxHeight: '100%',
  margin: { y: 'auto', x: 'auto' },
  fontSize: giga,
  [minTinyMediaQuery]: {
    fontSize: pixelsToUnit(100),
  },
  [minSmallMediaQuery]: {
    fontSize: pixelsToUnit(130),
  },
  [minMediumMediaQuery]: {
    fontSize: giga,
  },
  [minHugeMediaQuery]: {
    fontSize: pixelsToUnit(130),
  },
});

const MaskContainer = styled(AspectRatio, {
  backgroundColor: '#323232',
  position: 'relative',
});

const CensorWrapper = styled.div({
  flexBasis: '50%',
});

const BrandCensor = styled.div({
  ...setCensorStyle(8, '50%', 16, 'auto'),
  [minTinyMediaQuery]: {
    ...setCensorStyle(14, '50%', 32, 'auto'),
  },
  [minMediumMediaQuery]: {
    ...setCensorStyle(14, '50%', 24, 0),
  },
});

const ProductNameCensor = styled.div({
  ...setCensorStyle(10, '80%', 5, 'auto'),
  [minTinyMediaQuery]: {
    ...setCensorStyle(14, '80%', 20, 'auto'),
  },
  [minMediumMediaQuery]: {
    ...setCensorStyle(18, '80%', 15, 0),
  },
});

const PriceCensor = styled.div({
  ...setCensorStyle(11, '50%', 20, 'auto'),
  [minMediumMediaQuery]: {
    ...setCensorStyle(20, 103, 37, 0),
  },
});

export default styled(MysteryCard);
